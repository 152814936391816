import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header/header"
import Embeds from "./embeds"
import Footer from "./footer/footer"
// import "./layout.css"
import "./style.css"
import "./hover.css"
// import "./layout.scss"
import "font-awesome/css/font-awesome.min.css"
import "bootstrap/dist/css/bootstrap.css"
import "react-lazy-load-image-component/src/effects/blur.css"

const Layout = ({ children }) => {
  // console.log(location)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Embeds />
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
