import React from "react"
import { Link } from "gatsby"
import Facebook from "../images/facebook.svg"
import Google from "../images/google.svg"
import Yelp from "../images/yelp.svg"
import Angies from "../images/angies-list.svg"
import BBB from "../images/BBB.svg"
// import Allreviews from "../reviews/reviewsData"
import { LazyLoadImage } from "react-lazy-load-image-component"

// review stars
import FiveStars from "../images/review-stars/5-stars.svg"
import FourSomethingStars from "../images/review-stars/4-something-stars.svg"

const ReviewsTable = () => {
  // const facebookReviews = Allreviews.filter(
  //   fb => fb.category === "Facebook Reviews"
  // )
  // const googleReviews = Allreviews.filter(
  //   fb => fb.category === "Google Reviews"
  // )
  // const yelpReviews = Allreviews.filter(fb => fb.category === "Yelp Reviews")
  return (
    <Link to="/reviews/" className="reviews-link-header">
      <div className="reviews-table">
        <div className="row no-gutters review-row">
          <div className="col-3 col-md-2 col-lg-4">
            <LazyLoadImage
              effect="blur"
              src={Facebook}
              alt="Facebook"
              height="15px"
            />
          </div>
          <div className="col-5 col-md-5 col-lg-3 pl-4 text-right text-md-left">
            <div className="d-inline-block text-left d-md-block text-md-center">
              <span className="star-value pl-2 pr-2 pl-md-4">4.9</span>
            </div>
            <div className="d-inline-block text-left d-md-block text-md-center">
              <LazyLoadImage
                effect="blur"
                src={FourSomethingStars}
                className="review-stars"
                alt="★★★★★"
              />
            </div>
          </div>
          <div className="col-4 col-md-5 text-right">
            <span className="review-count">
              {/* {facebookReviews.length}  */}
              144 reviews
            </span>
          </div>
        </div>
        <div className="row no-gutters review-row">
          <div className="col-3 col-md-2 col-lg-4">
            <LazyLoadImage
              effect="blur"
              src={Google}
              alt="Google"
              height="19px"
            />
          </div>
          <div className="col-5 col-md-5 col-lg-3 pl-4 text-right text-md-left">
            <div className="d-inline-block text-left d-md-block text-md-center">
              <span className="star-value pl-2 pr-2 pl-md-4">4.8</span>
            </div>
            <div className="d-inline-block text-left d-md-block text-md-center">
              <LazyLoadImage
                effect="blur"
                src={FourSomethingStars}
                className="review-stars"
                alt="★★★★★"
              />
            </div>
          </div>
          <div className="col-4 col-md-5 text-right">
            <span className="review-count">
              {/* {googleReviews.length}  */}
              307 reviews
            </span>
          </div>
        </div>
        <div className="row no-gutters review-row">
          <div className="col-3 col-md-2 col-lg-4">
            <LazyLoadImage effect="blur" src={Yelp} alt="yelp" height="24px" />
          </div>
          <div className="col-5 col-md-5 col-lg-3 pl-4 text-right text-md-left">
            <div className="d-inline-block text-left d-md-block text-md-center">
              <span className="star-value pl-2 pr-2 pl-md-4">5</span>
            </div>
            <div className="d-inline-block text-left d-md-block text-md-center">
              <LazyLoadImage
                effect="blur"
                src={FiveStars}
                className="review-stars"
                alt="★★★★★"
              />
            </div>
          </div>
          <div className="col-4 col-md-5 text-right">
            <span className="review-count">
              {/* {yelpReviews.length}  */}
              24 reviews
            </span>
          </div>
        </div>
        <div className="row no-gutters justify-content-between review-row">
          <div className="col-4">
            <LazyLoadImage
              effect="blur"
              src={Angies}
              alt="Angie's List"
              height="15px"
            />
          </div>
          <div className="col-4 text-center">
            <span className="letter-rating">A</span>
          </div>
          <div className="col-4 text-right">
            <span className="review-count"> </span>
          </div>
        </div>
        <div className="row no-gutters justify-content-between review-row">
          <div className="col-4">
            <LazyLoadImage effect="blur" src={BBB} alt="BBB" height="17px" />
          </div>
          <div className="col-4 text-center">
            <span className="letter-rating">A+</span>
          </div>
          <div className="col-4 text-right"></div>
        </div>
      </div>
    </Link>
  )
}
export default ReviewsTable
