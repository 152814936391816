import React from "react"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import Logo from "../../images/logo.svg"
import Searchicon from "../../images/searchcon.png"
import TooltipReviews from "../../components/tooltip-reviews"
import TooltipSurgery from "../tooltip-surgery"
import TooltipDoctors from "../tooltip-doctors"
import TooltipResources from "../tooltip-resources"
import Search from "../react-search-comp"
import "./header.scss"
import { useStaticQuery, graphql, Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"

import { StaticImage } from "gatsby-plugin-image"

const Header = () => {
  const pages = useStaticQuery(graphql`
    query HeaderQuery {
      allSitePage {
        edges {
          node {
            path
          }
        }
      }
    }
  `)
  const data = pages.allSitePage
  return (
    <header>
      <Sticky
        className="sticky-wrapper"
        stickyClassName="is-sticky"
        stickyStyle={{ transform: "unset", zIndex: "99" }}
      >
        <div className="header">
          <div className="mobile-header">
            <div className="container-fluid">
              <div className="row justify-content-between">
                <div className="col-3">
                  <SideBarMenu data={data} />
                </div>
                <div className="col-9">
                  <div className="mobile-logo px-md-3">
                    <Link to="/">
                      <StaticImage
                        src="../../images/logo.svg"
                        alt="Colorado Canine Orthopedics"
                        imgClassName="w-100"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="d-block mt-2 text-right">
                <a href="tel:719.264.6666" className="header-phone">
                  (719) 264.6666
                </a>
              </div>
            </div>
          </div>

          <div className="desktop-header">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-6">
                  <Link to="/" className="logo-btn">
                    <LazyLoadImage
                      effect="blur"
                      src={Logo}
                      className="header-logo-img"
                      alt="Logo"
                    />
                  </Link>
                </div>
                <div className="col-6" style={{ position: "relative" }}>
                  <div className="d-block pt-3">
                    <div className="d-flex justify-content-between">
                      <div className="">
                        <div className="header-reviews tooltip-wrap">
                          <div className="d-flex">
                            <StaticImage
                              src="../../images/stars-group.svg"
                              alt="Review Start"
                              imgClassName="img-fluid header-stars"
                            />{" "}
                            <span className="header-reviews-title mt-1">
                              Reviews
                            </span>
                          </div>
                          <TooltipReviews />
                        </div>
                      </div>
                      <div className="">
                        <div className="header-search">
                          <LazyLoadImage
                            effect="blur"
                            className="search-icon"
                            src={Searchicon}
                            alt="search-icon"
                            style={{ marginTop: "10px" }}
                          />
                          <Search data={data} />
                        </div>
                      </div>
                      <div className=" mt-2 text-right">
                        <a href="tel:719.264.6666" className="header-phone">
                          (719) 264.6666
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="header-nav-wrapper">
                    <ul className="nav justify-content-between">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li className="tooltip-wrap-menu">
                        <span className="tooltip-menu-nav">
                          SURGERY
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 213.333 213.333"
                            width="10"
                            height="10"
                            className="ml-2"
                          >
                            <path d="M0 53.333L106.667 160 213.333 53.333z" />
                          </svg>
                        </span>
                        <TooltipSurgery />
                      </li>
                      <li className="tooltip-wrap-menu">
                        <span className="tooltip-menu-nav">
                          DOCTORS
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 213.333 213.333"
                            width="10"
                            height="10"
                            className="ml-2"
                          >
                            <path d="M0 53.333L106.667 160 213.333 53.333z" />
                          </svg>
                        </span>
                        <TooltipDoctors />
                      </li>
                      <li className="tooltip-wrap-menu">
                        <span className="tooltip-menu-nav">
                          RESOURCES
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 213.333 213.333"
                            width="10"
                            height="10"
                            className="ml-2"
                          >
                            <path d="M0 53.333L106.667 160 213.333 53.333z" />
                          </svg>
                        </span>
                        <TooltipResources />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sticky>
    </header>
  )
}

export default Header
