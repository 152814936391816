import React from "react"
import Helmet from "react-helmet"

export default () => {
  return (
    <Helmet>

      /* Google Tag Manager */
      <script>
      {`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-THKLV5W');
      `}
      </script>
      /* /Google Tag Manager */

      /* Otto Widget */
      <script>
      {`
      (function (id, win, doc) {
        win.televet = win.televet || { id };
        win.otto = win.otto || { id };
        var o = doc.createElement('script');
        o.async = true;
        o.src = 'https://connect.televet.com/shim.js';
        var r = doc.getElementsByTagName('script')[0];
        r.parentNode.insertBefore(o, r);
      })('clo7fcic202re6e01fvdhfkjp', window, document);
      `}
      </script>
      /* /Otto Widget */

    </Helmet>
  )
}
