import React from "react"
import { Link } from "gatsby"

const TooltipSurgery = () => {
  return (
    <div className="tooltip-menu tooltiptext">
      <div id="triangle-up"></div>
      <div className="tooltip-nav">
        <ul>
          <li>
            <Link to="/acl-tears/">ACL Tears</Link>
            <Link to="/tplo/">TPLO</Link>
            <Link to="/hip-dysplasia/">Hip Dysplasia</Link>
            <Link to="/total-hip-replacement/">Total Hip replacement</Link>
            <Link to="/medial-patella-luxation/">Patella Luxations</Link>
            <Link to="/elbow-dysplasia/">Elbow Dysplasia</Link>
            <Link to="/canine-shoulder-disorders/">Shoulder Disorders</Link>
            <Link to="/fractures-dislocations/">Fractures</Link>
            <Link to="/arthroscopy/">Arthroscopy</Link>
            <Link to="/disc/">Intervertebral Disc Disease</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
export default TooltipSurgery
