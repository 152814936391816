import React from "react"
import { Link } from "gatsby"
import { Nav, Accordion, Card } from "react-bootstrap"
import Logo from "../../images/logo.png"
import Search from "../react-search-comp"
import Searchicon from "../../images/searchcon.png"
import "./menu.scss"
import { LazyLoadImage } from "react-lazy-load-image-component"

import { StaticImage } from "gatsby-plugin-image"

export default ({ data }) => (
  <div className="navigation">
    <Nav className="flex-column">
      <StaticImage
        src="../../images/logo.svg"
        alt="Colorado Canine Orthopedics"
        imgClassName="img-fluid logo mb-4"
      />
      <Nav.Item>
        <Link to="/">Home</Link>
      </Nav.Item>
      <Accordion>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="surgery">
            Surgery
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="surgery">
            <Card.Body>
              <Nav.Item>
                <Link to="/acl-tears/">ACL Tears</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/tplo/">TPLO</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/hip-dysplasia/">Hip Dysplasia</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/total-hip-replacement/">Total Hip Replacement</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/medial-patella-luxation/">Patella Luxations</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/elbow-dysplasia/">Elbow Dysplasia</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/canine-shoulder-disorders/">Shoulder Disorders</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/fractures-dislocations/">Fractures</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/arthroscopy/">Arthrocospy</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/disc/">Intervertebral Disc Disease</Link>
              </Nav.Item>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="doctors">
            Doctors
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="doctors">
            <Card.Body>
              <Nav.Item>
                <Link to="/doctors/dr-michael-detora">
                  Dr. Michael DeTora DVM DACVS
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/doctors/dr-scot-swainson">
                  Dr. Scot Swainson DVM DACVS
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/doctors/dr-andra-detora">
                  Dr. Andra DeTora DVM CCRT cVMA
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/doctors/dr-brian-dent">Dr. Brian Dent DVM DACVS</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/doctors/dr-leah-hixon">Dr. Leah Hixon DVM DACVS</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/doctors/dr-lindsay-brooks">Dr. Lindsay Brooks (Peterson) DVM DACVS</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/doctors/dr-nic-cabano">Dr. Nic Cabano DVM DACVS DACVSMR</Link>
              </Nav.Item>
              <li></li>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="resources">
            Resources
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="resources">
            <Card.Body>
              <Nav.Item>
                <Link to="/appointments-directions">
                  Appointments & Directions
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/preparing-surgery">Preparing Surgery</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/hospital-gallery">Hospital Gallery</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/payment-options">Payment Options</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/reviews/">Reviews</Link>
              </Nav.Item>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Nav>
    <div className="header-search mt-5">
      <LazyLoadImage
        effect="blur"
        className="search-icon"
        src={Searchicon}
        alt="search-icon"
        style={{ marginTop: "10px" }}
      />
      <Search data={data} />
    </div>
  </div>
)
