import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import FilterResults from "react-filter-search"

const TestSearch = ({ data }) => {
  const [allPage, setAllPage] = useState([])
  const [defValue, setdefValue] = useState("")
  const [show, setShow] = useState(false)

  useEffect(() => {
    let titData = []
    const filteredPages = data.edges.length
    for (let index = 0; index < filteredPages; index++) {
      titData.push({
        path: data.edges[index].node.path,
        title: makeTitle(data.edges[index].node.path),
      })
    }
    if (titData.length !== 0) {
      const noBAdata = titData.filter(
        (item) => !item.path.includes("/articles")
      )
      // console.log("noBAdata", noBAdata)
      setAllPage(noBAdata)
    }
    // console.log(titData)
    // console.log(filteredPages)
  }, [data.edges])

  const handleChange = (event) => {
    if (event.target.value.length !== 0) {
      setShow(true)
      setdefValue(event.target.value)
    } else {
      setShow(false)
      setdefValue("")
    }
  }
  const style = {
    position: "absolute",
  }
  // console.log(allPage)
  function makeTitle(slug) {
    return slug
      .replace(/-/g, " ")
      .replace(/\b[a-z]/g, function () {
        return arguments[0].toUpperCase()
      })
      .replace(/^\/|\/$/g, "")
  }
  return (
    <div>
      <input
        type="text"
        onChange={handleChange}
        value={defValue}
        className="searchBoxHead"
        id="forsrch"
      />
      <label for="forsrch"></label>
      <FilterResults
        value={defValue}
        data={allPage}
        renderResults={(results) => (
          <div
            className={`result ${show ? "d-block" : "d-none"}`}
            style={style}
          >
            <ul className="list-unstyled text-left mb-0">
              {results.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item.path}>{item.title}</Link>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      />
    </div>
  )
}
export default TestSearch
