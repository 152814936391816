import React from "react"
import Message from "../../images/message.png"
import ReviewStar from "../../images/stars-group.svg"
import { Link } from "gatsby"
import ReviewsTable from "../../components/reviews-table"
import { LazyLoadImage } from "react-lazy-load-image-component"

import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="container">
        <div className="row flex-column-reverse flex-md-row">
          <div className="col-md-4 col-lg-4 col-xl-5 contact-f">
            <div className="footer-mobile-padding">
              <h3>CONTACT US</h3>
              <p>
                Colorado Canine Orthopedics
                <br />
                5528 North Nevada
                <br />
                Colorado Springs, CO 80918
              </p>
              <p>
                Tel: <a href="tel:719-264-6666">719-264-6666</a> <br />
              </p>
              <div className="connect mt-4">
                {" "}
                <h3 className="mr-3 mt-2">
                  CONNECT WITH US!
                  <a
                    href="https://www.facebook.com/CanineOrtho/"
                    target="_blank"
                    className="mx-2"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 167.657 167.657"
                      alt="Facebook"
                    >
                      <path
                        d="M83.829.349C37.532.349 0 37.881 0 84.178c0 41.523 30.222 75.911 69.848 82.57v-65.081H49.626v-23.42h20.222V60.978c0-20.037 12.238-30.956 30.115-30.956 8.562 0 15.92.638 18.056.919v20.944l-12.399.006c-9.72 0-11.594 4.618-11.594 11.397v14.947h23.193l-3.025 23.42H94.026v65.653c41.476-5.048 73.631-40.312 73.631-83.154 0-46.273-37.532-83.805-83.828-83.805z"
                        fill="#626162"
                        data-original="#010002"
                        xmlns="http://www.w3.org/2000/svg"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://instagram.com/canineortho"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 512.001 512.001"
                      alt="Instagram"
                    >
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        d="M373.406 0H138.594C62.172 0 0 62.172 0 138.594V373.41C0 449.828 62.172 512 138.594 512H373.41C449.828 512 512 449.828 512 373.41V138.594C512 62.172 449.828 0 373.406 0zM256 395.996c-77.195 0-139.996-62.8-139.996-139.996S178.804 116.004 256 116.004 395.996 178.804 395.996 256 333.196 395.996 256 395.996zM399.344 149.02c-22.813 0-41.367-18.555-41.367-41.368s18.554-41.37 41.367-41.37 41.37 18.558 41.37 41.37-18.558 41.368-41.37 41.368zm0 0"
                        fill="#626162"
                        data-original="#000000"
                      />
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        d="M256 146.02c-60.64 0-109.98 49.335-109.98 109.98 0 60.64 49.34 109.98 109.98 109.98 60.645 0 109.98-49.34 109.98-109.98 0-60.645-49.335-109.98-109.98-109.98zm0 0M399.344 96.3c-6.258 0-11.352 5.095-11.352 11.352 0 6.258 5.094 11.352 11.352 11.352 6.261 0 11.355-5.09 11.355-11.352 0-6.261-5.094-11.351-11.355-11.351zm0 0"
                        fill="#626162"
                        data-original="#000000"
                      />
                    </svg>
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-xl-3 reviews-f">
            <div className="footer-mobile-padding">
              <div className="review-f-heading">
                <h3 style={{ letterSpacing: 2 }}>
                  <LazyLoadImage
                    effect="blur"
                    className="img-fluid mb-0 mr-2"
                    src={ReviewStar}
                    alt="revstar"
                    style={{ width: "50px" }}
                  />
                  REVIEWS
                </h3>
              </div>
              <div className="reviews-table">
                <ReviewsTable />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-xl-4 consultation-f text-white ">
            <div className="d-flex align-items-center">
              <div className="consult-img-cont float-left">
                <LazyLoadImage effect="blur" src={Message} alt="consult" />
              </div>

              <h3 className="pl-3 mb-0" style={{ letterSpacing: 2 }}>
                {" "}
                CONSULTATION
              </h3>
            </div>
            <p className="mt-3 text-white">
              Call our office today and speak with one of our Pet Care
              Coordinators to schedule a consultation.
            </p>

            <div className="row text-center footer-btn-row">
              <div className="col-6 col-md-12 col-xl-6 p-0">
                <div className="spacer h-100">
                  <a
                    className="btn btn-footer w-100 h-100"
                    href="tel:719-264-6666"
                  >
                    719-264-6666
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-footer">
        <div className="container">
          © {new Date().getFullYear()} by Colorado Canine Orthopedics Designed and Powered by 
          <a target="_blank" href="https://itspecialists.net/" rel="noopener noreferrer">IT Specialists, Inc.</a>
        </div>
      </div>
    </footer>
  )
}
export default Footer
